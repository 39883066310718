import stock1 from '../SlideShow/stock1.jpg';
import stock2 from '../SlideShow/stock2.jpg';
import stock3 from '../SlideShow/stock3.jpg';
import stock4 from '../SlideShow/stock4.jpg';
import stock5 from '../SlideShow/stock5.jpg';
import stock6 from '../SlideShow/stock6.jpg';
import '../CSS/main.css';
import '../CSS/Carousel.css';


export default function Carousel() {
    return (
        <div id="slideshow" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner" >
                <div className="carousel-item active">
                    <img src={stock1} className="d-block w-100" style={{ objectFit: "contain", height: "100%" }} />
                </div>
                <div className="carousel-item">
                    <img src={stock2} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={stock3} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={stock4} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={stock5} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={stock6} className="d-block w-100" alt="..." />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#slideshow" data-bs-slide="prev" style={{ width: "5%" }}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#slideshow" data-bs-slide="next" style={{ width: "5%" }}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            <div className="carousel-text">
                <span className="satisfy-regular">Make Life Shine</span>
            </div>
        
        </div>
    )
}