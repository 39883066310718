import { Outlet, Link } from "react-router-dom";
import logo from '../SwirlLogoNoBG.png';

const Layout = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="#" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={logo} alt="RNSLogo" style={{ transform: "scale(1.25)", marginLeft: "0.25em", marginRight: "0.50em" }} width="30" height="25" className="d-inline-block align-text-top" />
            <span style={{ color: "#008dd2", fontFamily: "serif" }}>Rise N Shine Cleaning Services LLC</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav" style={{ fontSize: ".8em", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <a className="nav-link active" aria-current="page" href="/" style={{ color: "#008dd2" }}>Home</a>
              <a className="nav-link" href="/services" style={{ color: "#008dd2" }}>Services</a>
              <a className="nav-link" href="/aboutUs" style={{ color: "#008dd2" }}>About Us</a>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />

      <div style={{ backgroundColor: "#008dd2" }}>
        <hr />
        <footer className="d-flex justify-content-start" style={{ backgroundColor: "#008dd2", padding: "1em", color: "white" }}>
          <div style={{ paddingRight: "3em" }}>
            <span style={{ fontSize: "10px" }}>RNS Cleaning Services LLC</span>
            <p style={{ fontSize: "10px" }}>West Springfield, MA 01089</p>
            <p style={{ fontSize: "8px" }}>&copy; 2022 Rise N Shine Cleaning Services LLC. All rights reserved.</p>
          </div>
          <div>
            <p style={{ fontSize: "10px", color: "white"}}><a href="/AboutUs">About Us</a></p>
            <p style={{ fontSize: "10px", color: "white"}}><a href="/Services">Services</a></p>
          </div>
        </footer>
      </div>
      <nav className="navbar navbar-expand-lg fixed-bottom" style={{ backgroundColor: "#008dd2" }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>          
            <button type="button" class="btn btn-sm btn-light"><img src={logo} alt="RNSLogo" width="25" height="20" /><span style={{ color: "#008dd2" }}>Book Your Cleaning</span></button>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav" style={{ fontSize: ".8em", display: "flex", alignItems: "center" }}>
              <span style={{ color: "white" }}>Call for a Free Estimate! (413) 218-9248 </span>
            </div>
            
          </div>
        </div>
      </nav>
    </>
  )
};

export default Layout;