import '../CSS/main.css';
import stock1 from '../SlideShow/stock1.jpg';
import stock2 from '../SlideShow/stock2.jpg';
import stock3 from '../SlideShow/stock3.jpg';
import stock4 from '../SlideShow/stock4.jpg';
import stock5 from '../SlideShow/stock5.jpg';
import stock6 from '../SlideShow/stock6.jpg';
import { useState, useEffect } from 'react';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import '../CSS/Home.css';


const Home = () => {
    const [adjective, setAdjective] = useState("simpler");
    const lifeAdjectives = ["better", "brighter", "cleaner", "happier", "healthier", "shinier", "simpler", "smoother", "sweeter"];

    useEffect(() => {

        const interval = setInterval(() => {
            setAdjective(prevAdjective => {
                const currentIndex = lifeAdjectives.indexOf(prevAdjective);
                const nextIndex = (currentIndex + 1) % lifeAdjectives.length;
                return lifeAdjectives[nextIndex];
            });
        }, 5000);

        return () => clearInterval(interval);

    }, []);




    return (
        <>
            <Carousel />
            <div className="row">
                <div className="col-lg-12 fw-bold themeColor text-center">
                    <p className="fs-2 mt-5">We make your days <span className="fade-in-out" style={{ marginLeft: "0.1em" }}>{adjective}.</span></p>
                </div>
                <div className="col-lg-12 text-center">
                    <p className="themeColor responsiveStyling mb-5">
                        Our purpose is to give you the best environment to focus on yours. Let us take care of the details, so you can focus on the big picture and the things that matter.
                    </p>
                    <h6 className="themeColor fs-2">Your Home and Office Cleaning Professionals</h6>
                    <p className="responsiveStyling">
                        RNS is a family owned and operated business servicing homes and businesses in the Western Massachusetts Area.
                        We are dedicated to providing the highest quality cleaning services to our clients.
                        We offer a variety of residential and commercial cleaning services to ensure your home or office is treated with the care and attention it deserves.
                    </p>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-5">
                <Card
                    title="LOCALLY OWNED"
                    text="Locally owned and operated. We don't just serve our community, we're a part of it."
                    icon="house-chimney" />
                <Card
                    title="AMAZING STAFF"
                    text="Attentive and friendly employees with years of experience in the cleaning industry."
                    icon="hand-sparkles" />
                <Card
                    title="PROVEN HISTORY"
                    text="We've been providing high quality cleaning services for over 20 years to residental and corporate clients in Western Massachusetts."
                    icon="award" />
            </div>
            <div className="row d-flex justify-content-center mb-5">
                <button type="button" className="btn border" style={{ backgroundColor: "#008dd2", width: "10em" }}>
                    <span>
                        <a className="nav-link fw-bold" href="/services" style={{ color: "white", fontSize: ".65em" }}>
                            VIEW OUR SERVICES
                        </a>
                    </span>
                </button>
            </div>
        </>
    );

};

export default Home;