import React from 'react';
import profilePic from '../SlideShow/profile.jpg';
import '../CSS/AboutUs.css';


const AboutUs = () => {
  return (

    <>
      <div className="row mt-5">
        <div className="col-lg-4 col-sm-12 text-center">
          <img
            src={profilePic}
            alt="About Us"
            className="img-fluid rounded-circle"
            style={{ height: "25em" }}
          />
          <p className="mt-2 mb-0 fw-bold fs-5">General Manager of RNS</p>
          <span className="mt-2 fs-6">Yelena Sergeychik</span>
        </div>
        <div className="col-lg-8 col-sm-12 text-center">
          <p className="themeColor fs-2 fw-bold mt-3">Our Purpose</p>
          <div className="aboutUs-desc">
          <p className="mt-3">
            For nearly two decades, our small, woman-owned business has been dedicated to delivering pristine results across Western Mass.
            From cozy homes to bustling businesses, we cater to all, offering one-time deep cleans and reliable recurring services.
            More than just cleaners, we're woven into the fabric of our community, committed to making every corner shine bright.
          </p>
          <p>
            Our team is made up of passionate professionals who take pride in their work.
            We're dedicated to delivering the highest quality service, and we're always looking for ways to improve.
            For us, cleaning is more than just a job—it's a calling. We're here to make your life easier, one clean at a time.
          </p>
          <p>
            A clean and tidy environment is key for a happy and healthy life and were commited to providing that for you!
          </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;