import React from 'react';

export default function Card({ title, text, icon }) {
    return (

        <div className="card border-0 text-center" style={{ width: "15rem", height: "15rem" }}>
            <div className="d-flex justify-content-center">
                <i class={`fa-solid fa-${icon}`} style={{color: "#008dd2", transform: "scale(2.5)"}}></i>
            </div>
            <div className="card-body">
                <span className="card-title"><span style={{color: "#008dd2", fontWeight: "bold", fontSize: ".85em"}}>{title}</span></span>
                <p className="card-text"><span style={{fontSize: ".75em"}}>{text}</span></p>
            </div>
        </div>


    )
}